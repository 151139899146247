import {
  Colors,
  Icon,
  MediaQuery,
  ProgressBar,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';
import { StepProgressBar } from '../../StepProgressBar';

export const StepsModalProgressBarDeal = styled(StepProgressBar)<{
  welcomeStep: boolean;
}>`
  display: none;
  position: absolute;
  bottom: 48px;

  span {
    background-color: rgb(
      ${({ welcomeStep }) => (welcomeStep ? Colors.White : Theme.colors.Brand)}
    );
    opacity: 0.3;

    &[aria-pressed='true'] {
      opacity: 1;
      background-color: rgb(
        ${({ welcomeStep }) =>
          welcomeStep ? Colors.White : Theme.colors.Brand}
      );
    }
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
    bottom: 40px;
  }
`;

export const MobileProgressBarDealContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  width: 80px;
  z-index: 3;
  top: 48px;
`;

export const MobileProgressBarDeal = styled(ProgressBar)`
  width: 80px;
  height: 4px;

  background-color: rgba(${Theme.colors.Brand}, 0.1);

  & div::before,
  & div::after {
    background-color: rgba(${Theme.colors.Brand}, 0.5);

    height: 4px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const CloseButtonIcon = styled(Icon)`
  font-size: 24px;
  margin-left: auto;
  padding: 32px 38px 0 0;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }

  &[data-is-welcome-step='true'] {
    color: rgb(${Colors.White});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    font-size: 32px;
  }
`;
