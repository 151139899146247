import {
  BodyHeavy,
  BodyLight,
  Colors,
  FontFamily,
  FontWeight,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  margin-top: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    flex-direction: row;
    gap: 42px;
    margin-top: 20px;
  }
`;

export const WrapperText = styled.div`
  width: 300px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 348px;
  }
`;

export const SmallText = styled.p`
  ${BodyLight}
  color: rgba(${Colors.Gray});
`;

export const SmallTextStrong = styled.p`
  ${BodyHeavy}
  color: rgba(${Colors.Gray});
`;

export const BigText = styled.p`
  font-family: ${FontFamily.DM_SANS};
  font-weight: ${FontWeight.BOLD};
  letter-spacing: -3px;
  font-size: 40px;
  line-height: 35px;

  margin: 12px 0;
  color: rgba(${Theme.colors.Brand});

  @media ${MediaQuery.SCREEN_MD_UP} {
    font-size: 56px;
    line-height: 44px;

    margin: 16px 0;
  }
`;

export const ValuesImage = styled.div`
  background-image: url('/assets/onboardingDeal/values-image-mobile.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 320px;
  height: 227px;
  background-size: cover;

  @media ${MediaQuery.SCREEN_MD_UP} {
    background-image: url('/assets/onboardingDeal/values-image.png');
    width: 342px;
    height: 331px;
  }
`;
